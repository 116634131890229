@use "../../../variables" as css;

.modal-body {
    .row {
        .row {
            .col-6 {
                .col-5 {
                    font-weight: 400;
                }
                .col-7 {
                    span {
                        cursor: pointer;
                    }
                }
            }
        }
        .partition {
            border-bottom: 1px solid #d8c9c9;
        }
    }
    .row {
        .img-container {
            height: 256px;
            width: 128px;
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
    }
}

.disable {
    max-width: none !important;
    text-align: left;
    padding: 30px !important;
    padding-bottom: 0 !important;
}

