@use "../../variables" as css;

:root {
    --nav-width: 65px;
    --z-fixed: 100;
    --nav-width-show: 265px;
}

.wrapper {
    min-width: 1150px;
    
    .wrapper-side-bar {
        display: flex;
        z-index: 1000;
        height: 100vh;
        overflow: overlay;
        box-shadow: 2px 0 2px -2px #333;

        .ps-sidebar-root {

            .ps-menu-root-content {
                overflow: overlay;
            }

            .ps-sidebar-container, .ps-submenu-content {
                background-color: map-get($map: css.$colors, $key: 3);

                .ps-menu-root {

                    .ps-menuitem-root {
                        
                        .ps-menu-button {
                            color: map-get($map: css.$colors, $key: 1) !important;
                            padding-left: 15px;

                            .ps-menu-icon {
                                i {
                                    font-size: 18px;
                                    text-align: center;
                                    width: 30px;
                                }
                            }

                            .ps-menu-label  {
                                color: map-get($map: css.$colors, $key: 1);
                                font-size: 14px;
                                font-weight: bold;
                            }

                            .ps-submenu-expand-icon {
                                span {
                                    width: 8px;
                                    height: 8px;
                                }
                            }

                            &:hover {
                                background-color: rgba(255, 255, 255, 0.527);
                                color: map-get($map: css.$colors, $key: 1) !important;
    
                                .ps-menu-label {
                                    color: map-get($map: css.$colors, $key: 1);
                                }
                                
                                &::before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    width: 6px;
                                    height: 50px;
                                    background-color: map-get($map: css.$colors, $key: 1);
                                }
                            }
                        }

                        &.ps-active {

                            .ps-menu-button {
                                background-color: #fff;
                                color: map-get($map: css.$colors, $key: 1) !important;
    
                                .ps-menu-label {
                                    color: map-get($map: css.$colors, $key: 1);
                                }
                                
                                &::before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    width: 6px;
                                    height: 50px;
                                    background-color: map-get($map: css.$colors, $key: 1);
                                }
                            }
                        }

                        &.ps-menu-logo {
                            padding: 0px;
                            cursor: pointer;
                            text-align: center;

                            .ps-menu-button {
                                pointer-events: none;
                                position: relative;
                                margin-left: -80px;
                                transition: 0.5s;
                                padding: 0px;
                                height: auto !important;

                                .ps-menu-label {
                                    display: grid;
                                    grid-template-columns: max-content max-content;
                                    align-items: center;
                                    column-gap: 2.5rem;
                                }

                                .text-app {
                                    color: map-get($map: css.$colors, $key: 1) !important;
                                    font-size: 30px;
                                }
    
                                .logo-app {
                                    width: 65px !important;
                                }
                            }
                        }
                    }
                }
            }
            
            .ps-submenu-content {
                z-index: 1500;
            }

            &.ps-collapsed {
                .ps-menu-button {
                    margin-left: 0px !important;
                }

                .ps-menu-logo {
                    .ps-menu-button {
                        .ps-menu-label {
                            font-size: 16px !important;
                        }
                    }
                }
            }
            
        }
    }

    .wrapper-container {
        position: relative;
        width: calc(100% - var(--nav-width));
        transition: 300ms;

        &.nav-show {
            width: calc(100% - var(--nav-width-show));

            .wrapper-header {
                width: calc(100% - var(--nav-width-show)) !important;
            }
        }

        .wrapper-header {
            position: fixed;
            top: 0;
            width: calc(100% - var(--nav-width));
            height: 70.44px;
            border-bottom: 5px solid map-get($map: css.$colors, $key: 3);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 20px;
            z-index: 999;
            transition: 300ms;
            background-color: map-get($map: css.$colors, $key: white);
            .user-info{
                img {
                    height: 30px;
                    width: 30px;
                    border-radius: 100%;
                }
            }
        }

        .wrapper-content {
            position: relative;
            top: 70.44px;
            padding: 30px;
            height: calc(100vh - 70.44px);
            background-color: #F5F5F5;
            overflow: overlay;

            .container-title {
                font-size: map-get($map: css.$fs, $key: fs-heading-large);
                font-weight: bold;
                margin-bottom: 30px;
                color: map-get($map: css.$colors, $key: 1);
            }

            .container-search, .container-content, .container-content-form {
                background-color: map-get($map: css.$colors, $key: white);
                padding: 30px;
                box-shadow: css.$shadow-box-default;
                border-radius: css.$radius-div;
            }
            
            .container-content-form {

                .sub-container-title {
                    font-size: map-get($map: css.$fs, $key: fs-heading-small);
                    margin-bottom: 20px;
                    font-weight: 700;
                    border-bottom: 1px dotted map-get($map: css.$colors, $key: 3);
                }

                .container-form-input {
                    padding: 30px;
                    // max-height: calc(100vh - 300px);
                    overflow: overlay;
                }

                .container-form-footer {
                    text-align: center;
                    border-top: 1px solid map-get($map: css.$colors, $key: light-gray);
                    margin-top: 30px;
                    padding-top: 30px;
                }
            }

            .container-search {
                margin-bottom: 40px;
            }
        }
    }

    .dropdownMenuInfo {
        top: 10px !important; 

        a {
            display: flex;
            align-items: center;
            color: map-get($map: css.$colors, $key: 3) !important;
            background-color: map-get($map: css.$colors, $key: white) !important;
            padding: 10px 20px;

            &:hover {
                color: map-get($map: css.$colors, $key: 1) !important;
            }

            i {
                margin-right: 8px;
                font-size: 20px;
            }
        }
    }
}

.container-not-authentication {
    .change-language {
        position: absolute;
        top: 10px;
        right: 20px;
    }
}