.input-card-select-file {
    width: 120px;
    height: 155px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    color: rgb(0, 119, 182);
    border: 2px solid rgb(0, 119, 182, 0.7);
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }

    i {
        font-size: 45px;
    }

    span {
        text-decoration: underline;
        font-weight: 500;
    }
}