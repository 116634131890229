@use "../../variables" as css;

.setting-card-screen {

    .noScrollTextarea {
        resize: none;
        overflow: hidden;
    }
}

.card-purchase-screen {

    .col-6-40 {
        width: calc(50% - 3px);
        border-radius: 4px;
    }
}

#modal-card-of-group {
    .modal-content {
        width: 90vw;
    }
}